define("discourse/plugins/discourse-calendar/discourse/adapters/templates/connectors/category-custom-settings/show-event-category-sorting-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if
    (or
      this.siteSettings.sort_categories_by_event_start_date_enabled
      this.siteSettings.disable_resorting_on_categories_enabled
    )
  }}
    <section>
      <h3>{{i18n
          "discourse_post_event.category.settings_sections.event_sorting"
        }}</h3>
  
      {{#if this.siteSettings.sort_categories_by_event_start_date_enabled}}
        <section class="field show-subcategory-list-field">
          <label>
            <Input
              @type="checkbox"
              @checked={{this.category.custom_fields.sort_topics_by_event_start_date}}
            />
            {{i18n
              "discourse_post_event.category.sort_topics_by_event_start_date"
            }}
          </label>
        </section>
      {{/if}}
  
      {{#if this.siteSettings.disable_resorting_on_categories_enabled}}
        <section class="field show-subcategory-list-field">
          <label>
            <Input
              @type="checkbox"
              @checked={{this.category.custom_fields.disable_topic_resorting}}
            />
            {{i18n "discourse_post_event.category.disable_topic_resorting"}}
          </label>
        </section>
      {{/if}}
    </section>
  {{/if}}
  */
  {
    "id": "v1aCGELs",
    "block": "[[[41,[28,[37,1],[[30,0,[\"siteSettings\",\"sort_categories_by_event_start_date_enabled\"]],[30,0,[\"siteSettings\",\"disable_resorting_on_categories_enabled\"]]],null],[[[1,\"  \"],[10,\"section\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"discourse_post_event.category.settings_sections.event_sorting\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"siteSettings\",\"sort_categories_by_event_start_date_enabled\"]],[[[1,\"      \"],[10,\"section\"],[14,0,\"field show-subcategory-list-field\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"sort_topics_by_event_start_date\"]]]],null],[1,\"\\n          \"],[1,[28,[35,2],[\"discourse_post_event.category.sort_topics_by_event_start_date\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"disable_resorting_on_categories_enabled\"]],[[[1,\"      \"],[10,\"section\"],[14,0,\"field show-subcategory-list-field\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"disable_topic_resorting\"]]]],null],[1,\"\\n          \"],[1,[28,[35,2],[\"discourse_post_event.category.disable_topic_resorting\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"or\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/adapters/templates/connectors/category-custom-settings/show-event-category-sorting-settings.hbs",
    "isStrictMode": false
  });
});