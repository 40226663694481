define("discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list-item", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _object, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>{{this.holiday.date}}</td>
  <td>{{this.holiday.name}}</td>
  <td>
    {{#if this.isHolidayDisabled}}
      <DButton
        @action={{action "enableHoliday" this.holiday this.region_code}}
        @label="discourse_calendar.enable_holiday"
      />
    {{else}}
      <DButton
        @action={{action "disableHoliday" this.holiday this.region_code}}
        @label="discourse_calendar.disable_holiday"
      />
    {{/if}}
  </td>
  */
  {
    "id": "t9/vY4Qr",
    "block": "[[[10,\"td\"],[12],[1,[30,0,[\"holiday\",\"date\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,[30,0,[\"holiday\",\"name\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"isHolidayDisabled\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@label\"],[[28,[37,2],[[30,0],\"enableHoliday\",[30,0,[\"holiday\"]],[30,0,[\"region_code\"]]],null],\"discourse_calendar.enable_holiday\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@label\"],[[28,[37,2],[[30,0],\"disableHoliday\",[30,0,[\"holiday\"]],[30,0,[\"region_code\"]]],null],\"discourse_calendar.disable_holiday\"]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/admin-holidays-list-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    tagName: "tr",
    classNameBindings: ["isHolidayDisabled:disabled"],
    loading: false,
    isHolidayDisabled: false,
    disableHoliday(holiday, region_code) {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)({
        url: `/admin/discourse-calendar/holidays/disable`,
        type: "POST",
        data: {
          disabled_holiday: {
            holiday_name: holiday.name,
            region_code
          }
        }
      }).then(() => this.set("isHolidayDisabled", true)).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    },
    enableHoliday(holiday, region_code) {
      if (this.loading) {
        return;
      }
      this.set("loading", true);
      return (0, _ajax.ajax)({
        url: `/admin/discourse-calendar/holidays/enable`,
        type: "DELETE",
        data: {
          disabled_holiday: {
            holiday_name: holiday.name,
            region_code
          }
        }
      }).then(() => this.set("isHolidayDisabled", false)).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
  }, [["method", "disableHoliday", [_object.action]], ["method", "enableHoliday", [_object.action]]])));
});